import { defineStore } from "pinia";
import {
  CurrencyEnum,
  PurchasedGift,
  SelectLinkDetails,
} from "../api.generated/scion";
import { getSelectLinkDetails } from "../services/selectLink";
import { isExpired } from "../utils/stores/utils";
import { useProductStore } from "./product";

export const useLinkDetailsStore = defineStore("linkDetails", {
  state: () => ({
    currentCredit: 0 as number,
    currency: CurrencyEnum.GBP,
    expiryDate: "" as string,
    hasBeenClicked: false as boolean,
    purchasedAssets: [] as PurchasedGift[],
    senderCustomerName: "" as string,
    logoUrl: "" as string,
    advert: {
      bannerImageUrl: "" as string,
      bannerLink: "" as string,
      mobileImageUrl: "" as string,
      mobileLink: "" as string,
      sidebarImageUrl: "" as string,
      sidebarLink: "" as string,
    },
    selfServeCustomisations: {
      cta_colour: "" as string,
      header: "" as string,
      logo: "" as string,
      media: "" as string,
      message: "" as string,
    },
    customisation: {
      senderName: "" as string,
      emailSubjectLine: "" as string,
      emailMessage: "" as string,
      emailBannerUrl: "" as string,
      logoUrl: "" as string,
      privacyPolicyUrl: "" as string,
      primaryColour: "" as string,
      backgroundColour: "" as string,
    },
  }),
  getters: {
    isLinkExpired(): boolean {
      return isExpired(this.expiryDate as string);
    },
    hasBannerAdvert(): boolean {
      return this.advert.bannerImageUrl !== "";
    },
    hasMobileAdvert(): boolean {
      return this.advert.mobileImageUrl !== "";
    },
    hasSidebarAdvert(): boolean {
      return this.advert.sidebarImageUrl !== "";
    },
  },
  actions: {
    setLinkDetails(details: SelectLinkDetails) {
      this.currentCredit = Number(details.currentCredit);
      this.currency = details.currency;
      this.expiryDate = details.expiryDate;
      this.hasBeenClicked = !!details.firstClickedAt;
      this.purchasedAssets = details.purchasedGifts;
      this.logoUrl = details.logoUrl;

      // Remove the GUID from the sender name
      // This GUID is used to make Runa customer names unique
      this.senderCustomerName = (details.senderCustomerName || "")
        .replace(/\([0-9a-fA-F]{8}\)$/, "")
        .trim();

      this.advert.bannerImageUrl = details.advertBannerImageUrl || "";
      this.advert.bannerLink = details.advertBannerLink || "";
      this.advert.mobileImageUrl = details.advertMobileImageUrl || "";
      this.advert.mobileLink = details.advertMobileLink || "";
      this.advert.sidebarImageUrl = details.advertSidebarImageUrl || "";
      this.advert.sidebarLink = details.advertSidebarLink || "";

      if (details.customisation) {
        this.customisation.senderName = details.customisation.senderName || "";
        this.customisation.emailSubjectLine =
          details.customisation.emailSubjectLine || "";
        this.customisation.emailMessage =
          details.customisation.emailMessage || "";
        this.customisation.emailBannerUrl =
          details.customisation.emailBannerUrl || "";
        this.customisation.logoUrl = details.customisation.logoUrl || "";
        this.customisation.privacyPolicyUrl =
          details.customisation.privacyPolicyUrl || "";
        this.customisation.primaryColour =
          details.customisation.primaryColour || "";
        this.customisation.backgroundColour =
          details.customisation.backgroundColour || "";
      }

      this.selfServeCustomisations = details.selfServeCustomisations;
    },

    async loadLinkDetails(token: string) {
      try {
        const productStore = useProductStore();
        const linkDetailsStore = useLinkDetailsStore();

        const selectLinkDetails = await getSelectLinkDetails(token, null);

        linkDetailsStore.setLinkDetails(selectLinkDetails);
        productStore.setCategories(selectLinkDetails.productCategories);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
});
