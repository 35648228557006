import { defineStore } from "pinia";
import {
  DenominationTypeEnum,
  ProductCategory,
  SelectLinkProduct,
} from "../api.generated/scion";
import { productCategories } from "../utils/categories";

export const useProductStore = defineStore("product", {
  state: () => ({
    products: [] as SelectLinkProduct[],
    categories: [] as ProductCategory[],
    currencySymbols: {} as Record<string, string>,
  }),

  getters: {
    categoriesAsMap(): { [key: string]: ProductCategory } {
      return this.categories.reduce(
        (map, category) => ({ ...map, [category.code]: category }),
        {}
      );
    },

    availableCategories(): ProductCategory[] {
      return productCategories(this.products);
    },
  },

  actions: {
    setProducts(products: SelectLinkProduct[]) {
      this.products = products.filter((product) => {
        const hasValidDenominations =
          product.denominationType !== DenominationTypeEnum.FIXED ||
          (product.availableDenominations &&
            product.availableDenominations.length > 0);

        return hasValidDenominations;
      });

      this.products.forEach((product) => {
        // for some currencies, the symbol comes though as a  mix of letters and a symbol e.g. US$
        // we need to remove the letters and just keep the symbols
        const symbol = product.currencySymbol.replace(/[a-zA-Z]/g, "");
        this.currencySymbols[product.currency] = symbol;
      });
    },

    setCategories(categories: ProductCategory[]) {
      this.categories = categories;
    },
  },
});
