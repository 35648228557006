import { SelectLinkProduct, ProductCategory } from "../api.generated/scion";
import { useProductStore } from "../stores";

export function productCategories(
  products: SelectLinkProduct[]
): ProductCategory[] {
  const productStore = useProductStore();

  const availableCategories: { [key: string]: ProductCategory } = {};
  const categoriesMap = productStore.categoriesAsMap;

  products.forEach((p) => {
    p.categories.forEach((code) => {
      availableCategories[code] = categoriesMap[code];
    });
  });

  return Object.values(availableCategories).filter((c) => c);
}
