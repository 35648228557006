import {
  SelectLinkDetails,
  SelectLinkOrder,
  SelectLinkOrderItem,
  SelectLinkOrderResponse,
  SelectLinkProduct,
  SelectLinkProducts,
  SelectLinkService,
} from "../api.generated/scion";
import { request as __request } from "../api.generated/scion/core/request";
import { BasketItem } from "../stores/basket";

export async function getSelectLinkDetails(
  token: string,
  exp: string | null | undefined
): Promise<SelectLinkDetails> {
  const expParam = exp === "1" || exp === "2" ? `?exp=${exp}` : "";
  try {
    return __request({
      method: "POST",
      path: `/v1/select-link-detail/${token}${expParam}`,
    });
  } catch (e) {
    console.error(`Error when calling select link api`, e);
    throw e;
  }
}

export async function makeSingleSelectLinkOrder(
  idempotencyKey: string,
  token: string,
  product: SelectLinkProduct,
  value: number
): Promise<SelectLinkOrderResponse> {
  try {
    const orderItem: SelectLinkOrderItem = {
      id: product.name,
      productCode: product.code,
      value: value,
    };
    return makeOrderRequest(idempotencyKey, token, [orderItem]);
  } catch (e) {
    console.error(`Error when calling select link api`, e);
    throw e;
  }
}

export async function makeMultipleSelectLinkOrders(
  idempotencyKey: string,
  token: string,
  basketItems: BasketItem[]
): Promise<SelectLinkOrderResponse> {
  try {
    const orderItems: SelectLinkOrderItem[] = basketItems.map((item) => {
      return {
        id: item.id,
        productCode: item.product.code,
        value: item.amount,
      };
    });
    return makeOrderRequest(idempotencyKey, token, orderItems);
  } catch (e) {
    console.error(`Error when calling select link api`, e);
    throw e;
  }
}

export async function makeOrderRequest(
  idempotencyKey: string,
  token: string,
  orderItems: SelectLinkOrderItem[]
): Promise<SelectLinkOrderResponse> {
  const requestBody: SelectLinkOrder = {
    idempotencyKey: idempotencyKey,
    items: orderItems,
  };
  return SelectLinkService.selectLinkOrder(token, requestBody);
}

export async function getSelectLinkProducts(
  token: string
): Promise<SelectLinkProducts> {
  try {
    return SelectLinkService.selectLinkProductList(token);
  } catch (e) {
    console.error(`Error when calling select link api`, e);
    throw e;
  }
}
