<script setup lang="ts">
import { useI18n } from "vue-i18n";
import DOMPurify from "dompurify";
import { computed } from "vue";

const { t } = useI18n();

const props = defineProps<{
  customerSupportUrl: string | undefined;
}>();

const formattedFeTimeoutMessage = computed(() => {
  return t("error.payouts_timeout_message", {
    msg: getFormattedCustomerSupportArg(props.customerSupportUrl),
  });
});

const getFormattedCustomerSupportArg = (
  customerSupportUrl: string | undefined
): string => {
  const msgArg = t("error.customer_support");
  if (customerSupportUrl) {
    return `<a href='${customerSupportUrl}' class="text-blue-500 underline">${msgArg}</a>`;
  }
  return msgArg;
};
</script>

<template>
  <div class="flex flex-col items-center gap-4">
    <p class="font-heading text-xl font-extrabold">
      {{ $t("message.balance_ready_soon") }}
    </p>
    <p
      class="max-w-[575px] text-center text-base"
      v-html="DOMPurify.sanitize(formattedFeTimeoutMessage)"
    ></p>
  </div>
</template>
