import { defineStore } from "pinia";

export const useTokenStore = defineStore("token", {
  state: () => ({
    token: "" as string,
  }),
  actions: {
    setToken(token: string) {
      this.token = token;
    },
  },
});
