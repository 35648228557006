<script setup lang="ts">
import { LoadingSpinner } from "@wegift/two-components";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
  <LoadingSpinner />
  <p class="font-heading mt-4 text-xl font-extrabold">
    {{ $t("message.loading_balance") }}
  </p>
  <p class="mt-4 text-base">{{ $t("message.please_do_not_refresh") }}</p>
</template>
