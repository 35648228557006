/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DenominationTypeEnum {
  OPEN = "OPEN",
  FIXED = "FIXED",
}
