import { ref, onMounted, onBeforeUnmount } from "vue";
import { TIMER_INTERVAL_1_SEC } from "../../constant";

export const useTimer = () => {
  const loadingTime = ref<number>(0);
  let timer: any;

  onMounted(() => {
    timer = setInterval(() => {
      loadingTime.value += TIMER_INTERVAL_1_SEC;
    }, TIMER_INTERVAL_1_SEC);
  });

  onBeforeUnmount(() => {
    clearInterval(timer);
  });

  return { loadingTime };
};
