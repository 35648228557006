import { defineStore } from "pinia";
import { SelectLinkProduct } from "../api.generated/scion";
import { v4 as uuidv4 } from "uuid";
import { validateAmountForProductAndRemainingBalance } from "../utils/validators";
import { useLinkDetailsStore } from "./linkDetails";
import { useIdempotencyKeyStore } from "./idempotencyKey";
import { event as gaEvent } from "vue-gtag";

export const MAX_NUMBER_OF_ITEMS = 5;
export const MAX_NUMBER_OF_ITEMS_ERROR_MESSAGE = `You cannot have more than ${MAX_NUMBER_OF_ITEMS} assets in your basket`;

export class BasketFullError extends Error {}
export class InvalidAmountError extends Error {}

export enum ProductSource {
  Popular = "Popular",
  Regular = "Regular",
}

export class BasketItem {
  id: string = uuidv4();
  created: Date = new Date();
  product: SelectLinkProduct;
  amount: number;
  source: ProductSource;

  constructor(
    product: SelectLinkProduct,
    amount: number,
    source: ProductSource
  ) {
    this.product = product;
    this.amount = amount;
    this.source = source;
  }
}

function onUpdate() {
  const idempotencyKeyStore = useIdempotencyKeyStore();
  idempotencyKeyStore.generateNewKey();
}

export const useBasketStore = defineStore("basket", {
  state: () => ({
    _items: [] as BasketItem[],
    _purchasingErrorMessage: "" as string,
    isOrderProcessing: false as boolean,
  }),
  getters: {
    getItems(): BasketItem[] {
      return this._items;
    },
    getNumberOfItems(): number {
      return this.getItems.length;
    },
    isCartEmpty(): boolean {
      return this.getNumberOfItems === 0;
    },
    getCartTotalAmount(): number {
      const cartTotalAmount = this.getItems
        .reduce((s, a) => s + +a.amount, 0)
        .toFixed(2);

      return parseFloat(cartTotalAmount);
    },
    getPurchasingErrorMessage(): string {
      return this._purchasingErrorMessage;
    },
  },
  actions: {
    addItem(
      product: SelectLinkProduct,
      amount: number,
      productSource: ProductSource
    ) {
      if (this.getNumberOfItems >= MAX_NUMBER_OF_ITEMS) {
        throw new BasketFullError(MAX_NUMBER_OF_ITEMS_ERROR_MESSAGE);
      }

      const linkDetailsStore = useLinkDetailsStore();

      const remainingBalance =
        linkDetailsStore.currentCredit - this.getCartTotalAmount;

      const errorMessage = validateAmountForProductAndRemainingBalance(
        amount,
        product,
        remainingBalance
      );

      if (errorMessage) {
        throw new InvalidAmountError(errorMessage);
      }

      gaEvent("add_to_cart", {
        hostname: window.location.hostname,
        items: [
          {
            id: product.code,
            name: product.name,
            price: amount,
            quantity: 1,
            item_category: productSource as string,
          },
        ],
      });

      const newItem = new BasketItem(product, amount, productSource);
      this._items.push(newItem);
      onUpdate();
    },
    removeItem(id: string) {
      const itemToRemove = this.getItems.find((x) => x.id === id);

      if (!itemToRemove) {
        console.error(`Cannot find basket item ${id}`);
        return;
      }

      gaEvent("remove_from_cart", {
        hostname: window.location.hostname,
        items: [
          {
            id: itemToRemove.product.code,
            name: itemToRemove.product.name,
            price: itemToRemove.amount,
            quantity: 1,
          },
        ],
      });

      this.getItems.splice(this.getItems.indexOf(itemToRemove), 1);
      onUpdate();
    },
    removeAllItems() {
      this._items = [];
      onUpdate();
    },
    setPurchasingErrorMessage(msg: string) {
      this._purchasingErrorMessage = msg;
    },
    setIsOrderProcessing(state: boolean) {
      this.isOrderProcessing = state;
    },
  },
});
