<script setup lang="ts">
const height = 60;
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="height"
    :height="height"
    :viewBox="`0 0 ${height} ${height}`"
    fill="none"
  >
    <rect :width="height" :height="height" :rx="height / 2" fill="#F6F6F6" />

    <path
      :transform="`translate(${height / 3},${height / 3})`"
      d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
      stroke="black"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      :transform="`translate(${height / 3},${height / 3})`"
      d="M10 5.90906V10.8181"
      stroke="black"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      :transform="`translate(${height / 3},${height / 3})`"
      d="M9.99991 14.9091C10.4518 14.9091 10.8181 14.5428 10.8181 14.0909C10.8181 13.639 10.4518 13.2727 9.99991 13.2727C9.54804 13.2727 9.18173 13.639 9.18173 14.0909C9.18173 14.5428 9.54804 14.9091 9.99991 14.9091Z"
      fill="black"
    />
  </svg>
</template>
