<script setup lang="ts">
import InitialLoader from "./InitialLoader.vue";
import StillLoading from "./StillLoading.vue";
import LongerThanExpected from "./LongerThanExpected.vue";
import { useTimer } from "../../utils/hooks/useTimer";
import { computed } from "@vue/reactivity";
import { TIMER_INTERVAL_10_SEC, TIMER_INTERVAL_30_SEC } from "../../constant";
import { onMounted } from "vue";

const { loadingTime } = useTimer();

const props = defineProps<{
  translationsLoaded: boolean;
  customerSupportUrl: string | undefined;
}>();

onMounted(() => {
  document.documentElement.style.setProperty("--bg-color-spend", "#F3F3F3");
});

const currentComponent = computed(() => {
  if (loadingTime.value < TIMER_INTERVAL_10_SEC) {
    return InitialLoader;
  } else if (loadingTime.value < TIMER_INTERVAL_30_SEC) {
    return StillLoading;
  } else {
    return LongerThanExpected;
  }
});
</script>
<template>
  <div
    class="absolute inset-20 flex flex-col items-center justify-center overflow-hidden"
  >
    <component
      :is="currentComponent"
      :translationsLoaded="translationsLoaded"
      v-bind="
        currentComponent === LongerThanExpected
          ? { customerSupportUrl: customerSupportUrl }
          : {}
      "
    />
  </div>
</template>
