import {
  DenominationTypeEnum,
  SelectLinkProduct,
} from "../api.generated/scion";

export const YOU_DONT_HAVE_ENOUGH_BALANCE_ERROR_MESSAGE =
  "You don't have enough balance";

export function validateAmountForProductAndRemainingBalance(
  amount: number,
  product: SelectLinkProduct,
  remainingBalance: number
): string {
  const amountAsNumber = +amount;
  const minimumValueAsNumber = +product.minimumValue;
  const maximumValueAsNumber = +product.maximumValue;
  const remainingBalanceAsNumber = +remainingBalance;

  const isGreaterThanMaxValue = amountAsNumber > maximumValueAsNumber;
  if (isGreaterThanMaxValue) {
    return `You can add a max. of ${product.currencySymbol}${product.maximumValue} to ${product.name}`;
  }

  const isLessThanMinValue = amountAsNumber < minimumValueAsNumber;
  if (isLessThanMinValue) {
    return `You must add a min. of ${product.currencySymbol}${product.minimumValue} to ${product.name}`;
  }

  const isFixedDenominationProductAndAmountNotInValues =
    product.denominationType == DenominationTypeEnum.FIXED &&
    !product.availableDenominations?.find(
      (value) => Number(value) === Number(amountAsNumber)
    );

  if (isFixedDenominationProductAndAmountNotInValues) {
    return `Invalid denomination for ${product.name}`;
  }

  const isAmountGreaterThanRemainingBalance =
    amountAsNumber > remainingBalanceAsNumber;
  if (isAmountGreaterThanRemainingBalance) {
    return YOU_DONT_HAVE_ENOUGH_BALANCE_ERROR_MESSAGE;
  }

  if (amount % 1 !== 0) {
    return "Amount cannot have decimal values";
  }

  return "";
}
