import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";

export const useIdempotencyKeyStore = defineStore("idempotency-key", {
  state: () => ({
    key: "" as string,
  }),
  actions: {
    getKey() {
      if (!this.key) {
        this.generateNewKey();
      }
      return this.key;
    },
    generateNewKey() {
      this.key = uuidv4();
    },
  },
});
