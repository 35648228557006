/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CurrencyEnum {
  AUD = "AUD",
  BGN = "BGN",
  CAD = "CAD",
  CHF = "CHF",
  CNY = "CNY",
  CZK = "CZK",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  HRK = "HRK",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  JPY = "JPY",
  KRW = "KRW",
  MYR = "MYR",
  MXN = "MXN",
  NOK = "NOK",
  NZD = "NZD",
  PLN = "PLN",
  RON = "RON",
  SGD = "SGD",
  SEK = "SEK",
  THB = "THB",
  TRY = "TRY",
  USD = "USD",
  ZAR = "ZAR",
}
