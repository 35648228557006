import { StoreGeneric } from "pinia";
import { PurchasedGift } from "../../api.generated/scion";
import { formatCurrencyValue } from "../format";

export function isExpired(expiryDateString: string): boolean {
  const expiryDate = new Date(expiryDateString);
  const now = new Date();

  return expiryDate < now;
}

export function getDaysDuration(expiryDateString: string) {
  const expiryDate = new Date(expiryDateString);
  const now = new Date();

  const milliSecondsRemaining = expiryDate.valueOf() - now.valueOf();
  const milliSecondsPerMinute = 60000;
  const minutesPerHour = 60;
  const hoursPerDay = 24;
  const daysRemaining =
    milliSecondsRemaining /
    (milliSecondsPerMinute * minutesPerHour * hoursPerDay);

  return Math.floor(daysRemaining);
}

export function getRemainingDuration(expiryDateString: string) {
  const remainingDays = getDaysDuration(expiryDateString);
  const remainingYears = Math.floor(remainingDays / 365);

  if (remainingDays < 365) {
    if (remainingDays < 1) {
      return "less than a day";
    }

    return `${remainingDays} day${remainingDays > 1 ? "s" : ""}`;
  }

  return `${remainingYears} year${remainingYears > 1 ? "s" : ""}`;
}

export function getCurrentCredit(linkDetailsStore: StoreGeneric) {
  return formatCurrencyValue(
    linkDetailsStore.currency,
    linkDetailsStore.currentCredit
  );
}

export function getCurrencySymbol(
  linkDetailsStore: StoreGeneric,
  productStore: StoreGeneric
) {
  return productStore.currencySymbols[linkDetailsStore.currency];
}

export function formatAssetValue(purchasedGift: PurchasedGift) {
  return formatCurrencyValue(
    purchasedGift.product.currency,
    Number(purchasedGift.value)
  );
}

export function getSenderName(linkDetailsStore: StoreGeneric) {
  if (linkDetailsStore.customisation.senderName) {
    return linkDetailsStore.customisation.senderName;
  }

  return linkDetailsStore.senderCustomerName;
}
